<template>
    <AppContainer>
        <Employees/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import Employees from '@/components/dashboard/employees/Employees.vue'
export default {
  components: {
    AppContainer,
    Employees
  }
}
</script>