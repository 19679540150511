<template>
<div class="saleteam-area">
    <div class="teams" v-for="(employee,index) in employees" :key="index">
        <router-link :to="{name:'editEmployee',params:{id:employee.id}}" class="edit-member blue-circle">
            <img class="img-fluid" src="../../../assets/images/icons/pencil.svg" alt="">
        </router-link>
        <div class="delete-member blue-circle" v-on:click="deleteEmployee(employee.id)">
            <img class="img-fluid" src="../../../assets/images/icons/Delete.svg" alt="">
        </div>
        <div class="team-img">
            <img class="img-fluid" src="../../../assets/images/profile/profile.svg" alt="" />
        </div>
        <div class="team-title">
            <h3>{{employee.user.name}}</h3>
            <!-- <p><span v-if="employee.role == 1">Employee</span><span v-if="employee.role == 2">Manager</span></p> -->
            <p v-if="employee.profession">{{employee.profession.title}}</p>
            <div class="member-status" :class="employee.status == 1 ? 'text-success':'text-danger'">
                <span v-if="employee.status == 1">Active</span>
                <span v-if="employee.status == 0">InActive</span>
            </div>
        </div>
        <div class="team-tags">
            <p>Active Days</p>
            <div class="permissions-section" >
                <ul>
                    <li v-for="hour in employee.employee_hours" :key="hour.id"><a href="#" v-if="hour.isOpen == 1">{{hour.title}}</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="teams" v-if="employees.length > 0">
        <div class="add-new">
            <router-link :to="{name:'addEmployee'}"><img class="img-fluid" src="../../../assets/images/icons/bigPlus.svg" alt="" /></router-link>
        </div>
    </div>
    <div class="teams" v-bind:style="addTeam"  v-else>
        <div class="add-new d-block mt-5" v-if="this.user_role== 'business'">
            <img src="../../../assets/images/employees/no-employee.svg" style="width: 150px">
            <!-- <h5 class="fw-bold">No Employees</h5> -->
            <p class="mb-3">You can be an independent professional <br> or work as a team.</p>
            <router-link :to="{name:'addEmployee'}" class="button rem-btn d-inline">Add Team Member</router-link>
        </div>
        <div class="add-new d-block mt-5" v-else>
            <img src="../../../assets/images/employees/no-employee.svg" style="width: 150px">
            <p class="mb-3">You can be an independent professional <br> or work as a team.</p>
        </div>
    </div>
</div>
</template>

<script>
// import $ from 'jquery'
import axios from 'axios'
export default {
    data(){
		return{
            employees: [],
             addTeam: {
                    height: '360px',
                    width: '36%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    margin: 'auto',
                },
                user_role : ""
		}
    },
    created() {
        this.getEmployees()
    },
    methods: {
        getEmployees(){
            let thiss = this
            axios.get('employees_list',{params:{profession:true,employeeHours:true}}).then(function (response) {
                if (response.data.status == 'success') {
                    thiss.employees = response.data.data
                    console.log(thiss.employees)
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message)
                }
            });
        },
        deleteEmployee(emp_id){
            console.log(emp_id)
        }
    },
    mounted() {
        this.user_role = this.$storage.getStorageSync('role')
    }

}
</script>
<style scoped>
.addTeam{
    height: 360px;
    width: 36%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}
.member-status {
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 1px;
}
.permissions-section {
    height: 62px;
    overflow: auto;
    overflow-x: hidden;
}
</style>